<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">TRAINING</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button squared variant="outline-primary" class="mr-1">
          <b-icon-cog/> Training Categories
        </b-button>
        <b-button squared variant="primary">
          <b-icon-plus></b-icon-plus> New Training
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
  
        <b-row class="m-0 w-100">
          <b-col>
            <b-select  class="form-select mr-2" 
            >
              <template #first>
                <b-select-option
               
                  selected
                  value="All Categories"
                >All Produce</b-select-option>
              </template>
            </b-select>
         
     
              <b-form class="d-inline-block">
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-model="searchfield"
                @keyup="searchFarmers"
              ></b-form-input>
              </b-form>
          
          </b-col>
          <b-col cols="auto">

        <div class="sidebar-width text-right">

         <button  class="btn-opt primary">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
           <button class="btn-opt primary">
           <b-icon-printer></b-icon-printer> Print
           </button>
           <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button>
    

      </div>
          </b-col>

        </b-row>
      
      
    </div>
    <b-table
      :fields="fields"
      :items="items"
      thead-class="bg-primary-tb text-primary-tb border-primary-tb"
    >
      <template #cell(name)="data">
        <!-- <b-avatar icon="person">{{ data.item.name[0] }}</b-avatar> -->
        {{ data.item.name }}
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount.toLocaleString() }}
      </template>
      <template #cell(paytype)="data">
        <b-link>{{ data.item.paytype }}</b-link>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    let value_chains = ["Coffee", "Soybeans", "Maize"];
    let searchfield = "";
    let fields = [
      "name",
      "contact",
      "size",
      "produce",
      "group",
      { key: "date", label: "Date Added" },
    ];
    let items = new Array(9);
    items.fill({
      name: "Mubiru Joseph",
      contact: "0701878656",
      size:"24 Acres",
      produce:"coffee",
      group: "N/A",
      date:"4 June 2020"
    });
    return {
      value_chains,
      searchfield,
      fields,
      items
    };
  },
};
</script>

<style>
</style>
